@import "../globals/variables";

#employment {
    padding-top: 8%;
    padding-left: 1%;
    text-align: left;
    height: 90vh;

    .employment-content {
        overflow: hidden;
        transition: display 0.5s ease;

        .employment-thumbnails {
            ul {
                margin-top: 5%;
                margin-left: 5%;
                list-style: none;
                border-radius: 10px; }
            li {
                border-left: 6px solid $employment-thumbs-border;
                padding-top: 2%;
                padding-bottom: 0.5%;
                padding-left: 2%;
                cursor: pointer;
                transition: color 0.2s ease, background-color 0.5s ease;
                &:hover {
                    background-color: $employment-thumbs-background;
                    color: white; } }

            .activeEmp {
                background-color: $employment-thumbs-background;
                border-left: 6px solid $employment-thumbs-border-active;
                color: $white; } }

        .employment-list {
            .employment-details-list {
                list-style: none; }

            .employment-details-list-item {
                display: none;
                width: 90%;
                padding-top: 4%;
                padding-left: 3%; }

            p {
                margin: 0;
                margin-top: 2%; }

            a, a:visited, a:active {
                color: black;
                font-weight: normal;
                text-decoration: none;
                &:hover {
                    color: #555555; } }

            .company-logo {
                width: 90%; }

            .description-list {
                list-style-type: disc;
                padding-top: 1%;
                padding-left: 4%;

                p {
                    color: $employment-description; } } } } }

@media screen and (max-width: 1030px) {
    #employment {
        min-height: auto;
        padding-top: 15%;
        .employment-content {
            margin-top: 5%;
            .employment-thumbnails {
                .employment-thumbnails-list {
                    padding-left: 0; } }
            .employment-list {
                .employment-details-list {
                    padding-left: 0; }
                .company-logo {
                    width: 100%; } } } } }

@media screen and (min-width: 768px) and (max-height: 500px) {
    #employment {
        height: auto; } }

@media screen and (max-width: 768px) and (max-height: 500px) {
    #employment {
        height: auto;
        padding-top: 25%;
        .employment-content {
            .employment-thumbnails {
                padding-top: 5%;
                .employment-thumbnails-list {
                    padding-left: 0;
                    margin: 0;
                    .employment-thumbnails-list-item {
                        border-left: 0;
                        border-top: 6px solid $employment-thumbs-border;
                        display: inline-block;
                        padding: 2% 4%; }
                    .activeEmp {
                        border-top: 6px solid $employment-thumbs-border-active; } } }

            .employment-list {
                margin-top: 5%;
                .employment-details-list {
                    padding-left: 0;
                    .employment-details-list-item {
                        width: 90%; } }
                .company-logo {
                    width: 20%; } } } } }

@media screen and (max-width: 600px) {
    #employment {
        min-height: 90vh;
        padding-top: 25%;
        .employment-content {
            .employment-thumbnails {
                padding-top: 5%;
                .employment-thumbnails-list {
                    padding-left: 0;
                    margin: 0;
                    .employment-thumbnails-list-item {
                        border-left: 0;
                        border-top: 6px solid $employment-thumbs-border;
                        display: inline-block;
                        padding: 2% 4%; }
                    .activeEmp {
                        border-top: 6px solid $employment-thumbs-border-active; } } }

            .employment-list {
                margin-top: 5%;
                .employment-details-list {
                    padding-left: 0;
                    .employment-details-list-item {
                        width: 90%; } }
                .company-logo {
                    width: 20%; } } } } }
