@import "../globals/variables";

.custom-navbar {
    padding: 0;
    transition: box-shadow 0.2s linear;
    background-color: $navbar-background;

    .home-link {
        padding-left: 6%; }
    .home-icon {
        font-size: 20px;
        color: $navbar-home-button;
        cursor: pointer;
        border: 0.5px solid $navbar-home-border;
        border-radius: 50%;
        transition: 0.5s;
        &:hover, &:active {
            background-color: $navbar-home-hover;
            color: $white; } }

    .navbar-toggler, .navbar-toggler:active {
        border-color: $resume-button-border;
        color: $navbar-link;
        outline: none; }

    #nav-list {
        padding: 5px 20px 5px;
        margin: 0;
        list-style: none; }

    .link {
        &:after {
            content: '';
            display: block;
            height: 3px;
            width: 0;
            background: transparent;
            transition: width 0.5s ease, background-color 0.5s ease; }

        &:hover:after {
            width: 100%;
            background: $navbar-link-underline; } }

    a, a:visited, a:hover, a:active {
        color: $navbar-link;
        font-weight: 400;
        font-size: 16px;
        display: block;
        padding: 6px 12px;
        text-decoration: none; }

    .navbar-toggle {
        margin-right: 3%; } }

.nav-active {
    box-shadow: 0 0 8px $navbar-bottom-shadow; }

.resume-button {
    a, a:visited, a:hover, a:active, a:not([href]) {
        border: 1px solid $lighter-theme;
        border-radius: 5px;
        text-decoration: none;
        font-size: 15px;
        transition: 0.5s;
        text-transform: capitalize;
        padding: 8px 18px;
        background-color: $resume-button-fill;
        color: $navbar-link;
        cursor: pointer;

        &:hover {
            background-color: $lighter-theme;
            color: $white; }
        &:active {
            outline: none; } }
    &:active {
        outline: none; } }
