@import "../globals/variables";

.sidebar {
    background-color: $sidebar-background;
    text-align: center;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 23%;
    width: 6%;

    ul {
        padding: 0;
        list-style: none; }

    li {
        margin: 20% 23%;
        padding-top: 20%;
        padding-bottom: 20%;
        cursor: pointer;
        transition: padding 0.4s ease;
        &:hover {
            padding-bottom: 30%;
            padding-top: 10%; } }

    .icon {
        font-size: 25px; }

    a {
        text-decoration: none; } }

@media screen and (max-width: 600px), (max-height: 600px) {
    .sidebar {
        display: none; } }



