
$light-theme: rgba(0, 234, 149, 0.4);
$lighter-theme: rgba(0, 215, 126, 0.88);
$primary-theme: rgb(0, 195, 120);
$darker-theme: rgb(0, 165, 93);
$dark-theme: rgb(0, 135, 55);

$white: white;
$paragraph-text: rgb(84, 90, 112);

//Header tags
$h1-tag-color: $primary-theme;
$h3-tag-color: $primary-theme;
$h4-tag-color: $primary-theme;

$paragraph-text-color: $paragraph-text;

//Home
$profile-image-border: $primary-theme;
$explore-button: $primary-theme;

//Navbar
$navbar-link: $lighter-theme;
$navbar-background: $white;
$navbar-link-underline: $lighter-theme;
$navbar-bottom-shadow: $light-theme;
$resume-button-border: $lighter-theme;
$resume-button-fill: $white;
$resume-button-hover-fill: $lighter-theme;
$resume-button-font-color-hover: $white;
$navbar-home-button: $lighter-theme;
$navbar-home-border: $lighter-theme;
$navbar-home-hover: $lighter-theme;

//Sidebar
$sidebar-background: $white;
$sidebar-icon-border: $white;
$sidebar-icon-border-hover: $light-theme;

//Projects
$projects-details-card-title-background: $lighter-theme;
$project-links: $darker-theme;
$carousel-arrows: #7f7f7f;
$carousel-arrows-background: rgba(124, 124, 124, 0);
$carousel-arrows-background-hover: rgba(215, 215, 215, 0.8);
$carousel-thumbnails-dots: $darker-theme;

//Employment component colors
$employment-thumbs-border: $primary-theme;
$employment-thumbs-border-active: $dark-theme;
$employment-thumbs-background: $lighter-theme;
$employment-description: rgb(76, 79, 94);

//Skills
$skills-bar-fill: $lighter-theme;
$skills-icons-border: $lighter-theme;
$skill-level-scale: $lighter-theme;
