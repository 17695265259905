@import "../globals/variables";

.skills {
    text-align: left;

    .content {
        overflow: hidden;
        transition: display 0.5s ease; }

    .skill-type {
        text-transform: capitalize; }

    .skill-bars {
        .skill-row {
            width: 90%; }
        ul {
            list-style: none;
            margin: 0;
            padding-top: 1%;

            Col {
                height: 2%; }

            li {
                color: white;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 10px;
                font-weight: 500;
                box-shadow: 0 0 1px 1px $skills-bar-fill;
                background: $skills-bar-fill;
                animation: 2s ease-out 0s 1 widthInFromLeft; }

            img, svg {
                border: 3px solid $skills-icons-border;
                animation: border .4s ease 1 forwards;
                background-color: white;
                border-radius: 50%;
                text-align: center;
                width: 35px; }

            .skill {
                padding-top: 1%;
                padding-bottom: 1.5%;
                li {
                    padding-left: 1%; } } } }

    .skill-levels {
        margin-bottom: 0;
        .row {
            padding-right: 15px; }
        ul {
            margin: 0;
            list-style: none; }
        li {
            display: inline-block; }

        .skill-level-col {
            padding-left: 7px;
            padding-right: 30px;
            height: 10px;
            width: 80%; }
        .skill-texts-col {
            width: 80%; }

        .skill-level {
            border-bottom: 2px solid $skill-level-scale;
            border-right: 2px solid $skill-level-scale;
            height: 10px;
            width: 25%;
            padding-right: 1%; }
        .skill-level-text {
            font-size: 13px;
            text-align: right;
            width: 25%; } } }

@media screen and (max-width: 1030px) {
    .skills {
        .skill {
            padding: 0; }
        .skill-bars {
            ul {
                li {
                    font-size: 13px; } }
            .skill-row {
                width: 100%; } }
        .skill-icon {
            display: none; }

        .skill-levels {
            .row {
                padding-right: 15px; }
            .skill-level-col, .skill-texts-col {
                padding-right: 15px;
                width: 100%;
                padding-left: 0; }
            .skill-level-text {
                font-size: 11px; } } } }

@media screen and (max-width: 600px) {
    .skills {
        margin-top: 5%;
        .skill-icon {
            display: none; }
        .skill {
            padding: 0; }
        .skill-bars {
            ul {
                li {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    font-size: 12px; } } } } }

//@media screen and (min-width: 2000px) and (min-height: 2000px)
//    .skills
//        .skill-bars
//            ul
//                li
//                    font-size: 50px
//        .skill-levels
//            .skill-level-text
//                font-size: 40px
//        .skill-bars
//            ul
//                img,svg
//                    width: 100px
