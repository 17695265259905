@import "variables";

h1 {
  font-family: "Courier New";
  margin: 0;
  color: $h1-tag-color;
  font-size: 30px;
  font-weight: bold; }

h3 {
  margin: 0;
  color: $h3-tag-color;
  font-size: 22px; }

h4 {
  margin: 0;
  margin-bottom: 5%;
  color: $h4-tag-color;
  font-size: 18px; }

h6 {
  font-size: 15px; }

p {
  color: $paragraph-text-color;
  font-size: 14px; }

p-code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: $paragraph-text-color;
  margin-top: 1%;
  font-size: 14px; }

@media screen and (max-width: 600px) {

  h1 {
    font-size: 25px; }

  h3 {
    font-size: 19px; }

  h4 {
    font-size: 14px; }

  p {
    font-size: 12px; } }

//@media screen and (min-width: 2000px) and (min-height: 2000px)
//  h1
//    font-size: 100px
//  h3
//    font-size: 50px
//  h4
//    font-size: 34px
//  p
//    font-size: 50px
