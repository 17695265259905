@import "../globals/variables";

#about {
    padding-left: 1%;
    padding-top: 10%;
    text-align: left;
    height: 100vh;

    .content {
        overflow: hidden;
        transition: display 0.5s ease; }

    .intro {
        animation: 1s ease-out 0s 1 fadeIn;

        .profile-photo {
            padding-top: 2%;
            text-align: center; } }

    a, a:visited, a:hover, a:active {
        color: $darker-theme;
        font-weight: bold;
        font-size: 14px;
        text-decoration: none; }

    .technologies {
        overflow: hidden;
        margin-right: 0;

        ul {
            margin: 0;
            list-style-type: square; }

        li {
            font-family: monospace;
            font-size: 14px; } } }

@media screen and (max-width: 1030px) {
    #about {
        height: auto;
        padding-top: 20%; } }

@media screen and (max-width: 600px) {
    #about {
        height: auto;
        padding-top: 20%;
        padding-right: 3%;
        .intro {
            margin-right: 10px;
            a {
                font-size: 13px; } }
        .technologies {
            li {
                font-size: 11px; } } } }

//@media screen and (min-width: 2000px) and (min-height: 2000px)
//    #about
//        .intro
//            a
//                font-size: 50px
//        .technologies
//            li
//                font-size: 45px
