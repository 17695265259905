@import "../globals/variables";

#projects {
    padding-top: 8%;
    padding-left: 1%;
    text-align: left; }


.projects-content {
    margin-top: 3%; }

.project-row {
    margin-bottom: 3%;
    width: 100%; }

.card-title {
    padding: 4% 4% 2% 4%;
    background-color: $projects-details-card-title-background;

    .project-title {
        color: white; } }

.card-body {
    padding: 1% 5%; }

.details {
    animation: 2s ease-out 0s 1 fadeIn; }

.project-details-card {
    height: 65vh;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.14);
    border-top: 1px solid $projects-details-card-title-background;
    border-radius: 12px;

    ul {
        list-style: none;
        padding-left: 0;
        text-align: right; }

    li {
        display: inline-block;
        margin-left: 9%; }

    .link-icon {
        color: $project-links;
        font-size: 20px;
        cursor: pointer;
        transition: color 0.2s linear;
        &:hover {
            color: white; } }

    .project-description {
        height: 12em;
        line-height: 1.5em;
        overflow: hidden; }

    .tech-icons-list {
        text-align: left;
        list-style: none;
        padding-left: 0;

        li {
            display: inline;
            margin-left: 5%; } }

    img {
        width: 15%; } }

.more-projects-link {
    a, a:visited, a:hover, a:active {
        text-decoration: none;
        border: 2px solid $lighter-theme;
        color: $explore-button;
        margin-left: 40%;
        font-size: 16px;
        transition: 0.5s;
        text-transform: capitalize;
        padding: 14px 24px;
        border-radius: 8px;
        font-weight: 500;
        &:hover {
            background-color: rgba(0, 165, 90, 0.09); } } }

@media screen and (max-width: 1290px) {
    #projects {
        .project-details-card {
            height: auto;
            .project-description {
                height: auto; } } } }

@media screen and (max-width: 1030px) {
    #projects {
        padding-top: 15%;
        .project-details-card {
            height: auto;
            .project-description {
                height: auto; } }
        .project-row {
            margin-bottom: 8%; } } }

@media screen and (min-width: 768px) and (max-height: 500px) {
    #projects {
        .project-details-card {
            height: auto;
            .project-description {
                height: auto; } } } }

@media screen and (max-width: 768px) and (max-height: 500px) {
    #projects {
        .project-details-card {
            height: auto;
            width: 80%;
            .project-description {
                height: auto; } }
        .tech-icons-list {
            img {
                width: 10%; } }
        .more-projects-link {
            a, a:visited, a:hover, a:active {
                margin-left: 28%;
                font-size: 16px;
                padding: 5px 15px;
                border-radius: 5px; } } } }


@media screen and (max-width: 600px) {
    #projects {
        padding-top: 25%;
        .project-details-card {
            height: auto; }
        .project-row {
            margin-bottom: 20%; }
        .card-title {
            padding: 5%;
            ul {
                margin-bottom: 0; } }
        .more-projects-link {
            a, a:visited, a:hover, a:active {
                font-size: 14px;
                padding: 5px 15px;
                margin-left: 28%;
                border-radius: 5px; } } } }
