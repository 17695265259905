@import "../globals/variables";

.home {
    padding-top: 12%;
    padding-left: 5%;
    text-align: left;

    h1 {
        font-family: "Courier New";
        margin: 0;
        color: $h1-tag-color;
        font-size: 50px; }

    h3 {
        font-family: "Calibri Light";
        color: $paragraph-text-color;
        font-size: 30px;
        margin: 1% 0; }

    .description {
        width: 40%;
        padding-left: 5%; } }

#explore-button {
    margin-left: 5%;
    padding: 8px 18px;
    margin-top: 1%;
    color: $explore-button;
    font-weight: bold;
    border: 1px solid $explore-button;
    border-radius: 8px;
    text-transform: capitalize;

    &:hover {
        background-color: rgba(0, 165, 90, 0.09); }

    &:active {
        outline: 0; } }

@media screen and (max-width: 1030px) {
    .home {
        padding-top: 20%;
        h1 {
            font-size: 35px; }
        h3 {
            font-size: 22px; }

        .description {
            padding-left: 10%;
            width: 60%; } } }

@media screen and (max-width: 600px) {
    .home {
        padding-top: 30%;
        .description {
            width: 90%; } } }

