@import "../globals/variables";

#contact {
    padding-top: 8%;
    padding-left: 1%;
    text-align: left;
    height: 100vh;
    width: 95%;

    ul {
        padding: 0;
        list-style: none;
        margin-top: 15%;
        margin-left: 25%; }
    li {
        padding-top: 5%;
        padding-bottom: 5%;
        height: 5%; }

    img {
        margin-top: 10%;
        margin-left: 15%;
        border: 8px double $profile-image-border;
        box-shadow: 0 0 2px 5px $light-theme;
        width: 40%;
        border-radius: 50%; }

    .contact-icon {
        font-size: 30px; }

    .ctext {
        padding-left: 5%;
        font-size: 17px; }

    .primary-contact-link {
        a, a:visited, a:hover, a:active {
            text-decoration: none;
            border: 2px solid $lighter-theme;
            color: $explore-button;
            margin-left: 25%;
            margin-top: 5%;
            font-size: 16px;
            transition: 0.5s;
            text-transform: capitalize;
            padding: 14px 24px;
            border-radius: 8px;
            font-weight: 500;
            &:hover {
                background-color: rgba(0, 165, 90, 0.09); } } } }

@media screen and (max-width: 1030px) {
    #contact {
        margin: 0;
        height: auto;
        width: 90%;
        padding-bottom: 10%;
        .contact-content {
            img {
                width: 70%; }
            .primary-contact-link {
                a, a:visited, a:hover, a:active {
                    margin-left: 25%; } } } } }

@media screen and (max-width: 768px) and (max-height: 500px) {
    #contact {
        height: auto;
        .contact-content {
            img {
                margin-left: 30%;
                width: 40%; }
            .primary-contact-link {
                a, a:visited, a:hover, a:active {
                    margin-left: 38%; } }
            ul {
                margin-left: 30%; } } } }

@media screen and (max-width: 600px) {
    #contact {
        margin: 0;
        height: auto;
        width: 70%;
        .contact-content {
            img {
                margin-top: 10%;
                margin-left: 25%;
                width: 70%; }
            .primary-contact-link {
                a, a:visited, a:hover, a:active {
                    margin-left: 35%; } }
            ul {
                margin-left: 15%; }
            .ctext {
                padding-left: 5%;
                font-size: 14px; } } } }






