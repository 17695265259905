@import "../globals/variables";

.carousel-main {
    height: 80vh;
    animation: 1s ease-out 0s 1 fadeIn; }

.carousel {
    .control-arrow, &.carousel-slider .control-arrow {
        -webkit-transition: all .25s ease-in;
        -moz-transition: all .25s ease-in;
        -ms-transition: all .25s ease-in;
        -o-transition: all .25s ease-in;
        transition: all .25s ease-in;
        //opacity: .8
        z-index: 2;
        border: 0;
        cursor: pointer;
        font-size: 32px; }

    .control-arrow {
        &:hover {
            opacity: 1; }
        &:before {
            margin: 0 0;
            display: inline-block;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            content: ""; } }

    .control-prev.control-arrow {
        left: 0;
        &:before {
            border-right: 8px solid $carousel-arrows;
            opacity: 0.7; } }
    .control-next.control-arrow {
        right: 0;
        &:before {
            border-left: 8px solid $carousel-arrows;
            opacity: 0.7; } }
    position: relative;
    width: 100%;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
    img {
        width: 100%;
        height: 65vh;
        display: inline-block;
        pointer-events: none; }

    .control-arrow {
        outline: 0; }

    &.carousel-slider {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        .control-arrow {
            top: 45%;
            padding: 2% 2% 4% 2%;
            height: 20%;
            position: absolute;

            background-color: $carousel-arrows-background;
            &:hover {
                background: $carousel-arrows-background-hover;
                opacity: 1; } } }

    .slider-wrapper {
        overflow: hidden;
        -webkit-transition: height .15s ease-in;
        -moz-transition: height .15s ease-in;
        -ms-transition: height .15s ease-in;
        -o-transition: height .15s ease-in;
        transition: height 0.15s ease-in;
        &.axis-horizontal .slider {
            -ms-box-orient: horizontal;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            .slide {
                flex-direction: column;
                flex-flow: column; } }
        &.axis-vertical {
            -ms-box-orient: horizontal;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -moz-flex;
            display: -webkit-flex;
            display: flex;
            .slider {
                -webkit-flex-direction: column;
                flex-direction: column; } } }

    .slider {
        padding: 0;
        position: relative;
        list-style: none;
        width: 100%;
        &.animated {
            -webkit-transition: all .35s ease-in-out;
            -moz-transition: all .35s ease-in-out;
            -ms-transition: all .35s ease-in-out;
            -o-transition: all .35s ease-in-out;
            transition: all 0.35s ease-in-out; } }

    .slide {
        min-width: 100%;
        position: relative;
        text-align: center;
        background: #ffffff;
        img {
            width: 100%;
            vertical-align: top;
            border: 0; } }

    .control-dots {
        bottom: 0;
        margin-bottom: 0;
        padding: 1% 0;
        text-align: center;
        height: 4vh;
        width: 100%;
        position: relative;
        .dot {
            -webkit-transition: opacity .25s ease-in;
            -moz-transition: opacity .25s ease-in;
            -ms-transition: opacity .25s ease-in;
            -o-transition: opacity .25s ease-in;
            transition: opacity .25s ease-in;
            opacity: .3;
            background: $carousel-thumbnails-dots;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            cursor: pointer;
            display: inline-block;
            margin: 0 8px;
            &.selected, &:hover {
                opacity: 1; } } }

    .carousel-status {
        display: none; }

    &:hover .slide .legend {
        opacity: 1; } }

@media screen and (max-width: 1290px) {
    .carousel-main {
        height: 65vh;
        img {
            width: 100%;
            height: 55vh; } } }

@media screen and (max-width: 1030px) {
    .carousel-main {
        height: 40vh;
        img {
            width: 100%;
            height: 30vh; } } }

@media screen and (min-width: 768px) and (max-height: 500px) {
  .carousel-main {
    height: 75vh;
    img {
      width: 100%;
      height: 68vh; }
    .control-dots {
        height: 7vh; }
    .carousel {
      &.carousel-slider {
          .control-arrow {
              height: 30%; } } } } }

@media screen and (max-width: 768px) and (max-height: 500px) {
  .carousel-main {
      height: 69vh;
      width: 80%;
      padding-top: 3%;
      img {
          width: 100%;
          height: 62vh; }
      .control-dots {
          height: 7vh; }
      .carousel {
          &.carousel-slider {
              .control-arrow {
                  height: 30%; } } } } }

@media screen and (max-width: 600px) {
    .carousel-main {
        height: 40vh;
        padding-top: 3%;
        img {
            width: 100%;
            height: 33vh; } } }
