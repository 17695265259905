@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%); }

  100% {
    transform: translateX(0); } }

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%); }

  100% {
    transform: translateX(0); } }

@keyframes widthInFromLeft {
  from {
    width: 30%; }
  to {
    max-width: 100%; } }

@keyframes fadeIn {
  from {
    opacity: 0.2; }
  to {
    opacity: 1; } }

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-2vw); }

  20% {
    opacity: 1;
    transform: translateX(-1vw); }

  80% {
    opacity: 1;
    transform: translateX(1vw); }

  100% {
    opacity: 0;
    transform: translateX(1vw); } }

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-2vw); }

  20% {
    opacity: 1;
    transform: translateX(-1vw); }

  80% {
    opacity: 1;
    transform: translateX(1vw); }

  100% {
    opacity: 0;
    transform: translateX(2vw); } }

@keyframes borderInFromLeft {
  from {
    border: 1px solid black;
    width: 25%; }
  to {
    border: 1px solid black;
    max-width: 100%; } }
